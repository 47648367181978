<template>
  <section class="main-body">
    <h3 class="subheading">Georestriction Profiles Manager</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 mt-4 class="half">
          <label class="label" for="profileName">Profile Name*</label>
          <v-text-field
            id="profileName"
            v-model="profile.attributes.profileName"
            solo
            flat
            @change="status = 'needsSaving'"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Profiles allow for predefined sets of countries. This enables you to reuse these sets on your videos, streams and packages. Any countries not in this list will be prevented access. These countries can be edited per asset once added to the asset.</p>

          <p><i>Please note that if you update a profile here it <b>will not</b> be applied to existing uses of the profile</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mt-4>
          <label class="label" for="countries">Countries*</label>
          <v-autocomplete
            id="countries"
            outline
            v-model="profile.attributes.countries"
            :items="countriesList"
            item-value="alpha2Code"
            item-text="enShortName"
            no-data-text="loading..."
            multiple
            solo
            flat
            clearable
            dense
            chips
            @change="status = 'needsSaving'"
            >
          </v-autocomplete>
        </v-flex>
      </v-layout>

      <template v-if="countriesList.length">
        <v-btn @click="selectAll()">Select all</v-btn>
        <v-btn @click="unSelectAll()">Unselect all</v-btn>
      </template>

      <template v-if="!newProfile">
        <p>Last updated: {{ profile.attributes.publicationData.updatedAt || profile.attributes.publicationData.createdAt }}</p>
        <p>Created: {{ profile.attributes.publicationData.createdAt }}</p>
      </template>
    </section>

    <template v-if="!newProfile">
      <h3 class="subheading">Tools</h3>
      <section class="section-container">
        <v-layout row>
          <v-flex mr-5 mt-4 class="half">
            <v-btn
              type="button"
              depressed
              color="error"
              @click="deleteProfile()"
            >
              Delete Profile
            </v-btn>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>Deleting a profile <i>will not</i> delete the current uses of this profile. It will prevent it from being used in the future.</p>
          </v-flex>
        </v-layout>
      </section>
    </template>

    <StickyFooter :errorMessage="errorMessage" :status="status" @buttonClicked="saveEvent()" />
  </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "GeorestrictionProfileEdit",

  components: {
    StickyFooter
  },

  data:() => ({
    newProfile: false,
    status: "saved",
    errorMessage: "",
    profile: {
      attributes: {
        profileName: "",
        countries: [],
      }
    },
    countriesList: [],
    countriesLoaded: false,
  }),

  mounted() {
    if (this.$route.params.id === "create-profile") {
      this.newProfile = true
      this.status = "needsSaving"
    } else {
      this.fetchProfile()
      this.newProfile = false
    }
    this.getCountries()
  },

  methods: {
    saveEvent() {
      if (this.profile.attributes.countries.length && this.profile.attributes.profileName) {
        if (this.newProfile) {
          this.createProfile()
        } else {
          this.saveProfile()
        }
      } else {
        alert("Please add a name and a minimum of 1 country")
      }
    },

    selectAll(){
      this.profile.attributes.countries = [...this.countriesList.map(obj => obj.alpha2Code)]
    },
    unSelectAll(){
      this.profile.attributes.countries = []
    },

    createProfile() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles/`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              attributes: {
                profileName: this.profile.attributes.profileName,
                countries: this.profile.attributes.countries
              }
            }
          }
        })
        .then(response => {
          this.$store.commit("completeLoading");
          this.$router.push("/streamline/")
        })
        .catch(e => {
          console.error("Problem creating profile", e);
          this.errorMessage = "Problem creating profile"
          this.$store.commit("completeLoading");
        });
      })
    },

    saveProfile() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles/${this.$route.params.id}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: this.profile.type,
              id: this.profile.id,
              attributes: {
                profileName: this.profile.attributes.profileName,
                countries: this.profile.attributes.countries
              }
            }
          }
        })
        .then(response => {
          console.log("Save profile:", response);
          this.fetchProfile()
        })
        .catch(e => {
          console.error("Problem saving profile", e);
          this.errorMessage = "Problem saving profile"
          this.$store.commit("completeLoading");
        });
      })
    },

    deleteProfile() {
      if (confirm("Are you sure you want to delete this profile? It will stay applied on any videos that had it selected")) {
        this.$store.commit("startLoading");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles/${this.$route.params.id}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            console.log("Delete profile:", response);
            this.$store.commit("completeLoading");
            this.$router.push("/streamline/")
          })
          .catch(e => {
            this.errorMessage = "Problem deleting profile"
            console.error("Problem deleting profile", e);
          });
        })
      }
    },

    fetchProfile() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles/${this.$route.params.id}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch profile:", response);
          this.profile = response.data.data
          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          this.errorMessage = "Problem fetching profile"
          console.error("Problem fetching profile", e);
        });
      })
    },

    getCountries() {
      this.countriesLoaded = false
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}/countries`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          this.countriesList = response.data
          this.countriesLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving countries", e)
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
